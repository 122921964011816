import React, { Component } from "react"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"
import A from "../../utility/link/A"
import "./landing.sass"
class Landing extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      //NEED CLOSING TAG
      // <BackgroundImage
      //   Tag="div"
      //   className="bg"
      //   backgroundColor={`#c9e0fc`}
      //   fadeIn={true}
      //   loading="eager"
      //   fluid={
      //     this.props.data.acf.background_image.localFile.childImageSharp.fluid
      //   }
      // >
      <>
        <div className="bg__overlay">
          <div className="bg__hero">
            <div className="bg__hero__image">
              <Img
                fluid={this.props.heroImage.localFile.childImageSharp.fluid}
                alt={this.props.heroImage.alt_text}
              />
            </div>
          </div>
          <div className="bg__scroll">
            {/* <A to="#about">
              <Img
                fluid={
                  this.props.data.acf.arrow.localFile.childImageSharp.fluid
                }
              />
            </A> */}
          </div>
        </div>
      </>
    )
  }
}

export default Landing
