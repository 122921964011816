import React, { Component } from "react"
import { Link } from "gatsby"
import "./header.sass"
import Content from "../utility/Content/Content"
import A from "../utility/link/A"
import Img from "gatsby-image"
import { throttle } from "lodash"
class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      navlinks: [
        {
          title: "Home",
          link: "/",
        },
        {
          title: "About",
          link: "about",
        },
        // {
        //   title: "Strategy",
        //   link: "strategy",
        // },
        {
          title: "Portfolio",
          link: "portfolio",
        },
        // {
        //   title: "Team",
        //   link: "team",
        // },
        {
          title: "Contact",
          link: "contact",
        },
      ],
      navOpen: false,
      onHome: false,
    }
    this.toggleNav = this.toggleNav.bind(this)
  }
  toggleNav() {
    const currentstate = this.state.navOpen
    this.setState({ navOpen: !currentstate })
  }
  // newNavLinks = this.props.allNames.map(name => {
  //   return {
  //     title: name.charAt(0).toUpperCase() + name.slice(1),
  //     link: `#${name}`,
  //   }
  // })

  componentDidMount() {
    this.setState({ onHome: window.location.pathname === "/" }, () => {
      if (this.state.onHome) {
        window.addEventListener(
          "scroll",
          throttle(this.checkForLanding, 700),
          true
        )
      }
    })
  }

  componentWillUnmount() {
    if (this.state.onHome) {
      window.removeEventListener("scroll", this.checkForLanding)
    }
  }

  landingToggle = value => this.setState({ onLanding: value })

  checkForLanding = () => {
    if (window.pageYOffset < 400) {
      this.setState({ onHome: true })
    } else {
      this.setState({ onHome: false })
    }
  }
  render() {
    return (
      <div
        className={`header__wrapper ${
          this.state.onHome ? "on-landing" : "off-landing"
        }`}
      >
        <div className="alt__section__container" style={{ background: "none" }}>
          <div className="alt__section__container__content fit ">
            <div className="header">
              <h1>
                <div className="header__logo">
                  <A to={"#home"}>
                    <Img
                      fluid={this.props.logo.localFile.childImageSharp.fluid}
                      alt={this.props.logo.alt_text}
                    />
                  </A>
                </div>
              </h1>
              {/* desktop nav */}
              <div className="desktop">
                <nav className="nav">
                  {this.state.navlinks.map((link, index) => (
                    <div className="nav__link" key={index}>
                      <A to={link.link}>{link.title}</A>
                    </div>
                  ))}
                </nav>
              </div>
              {/* mobile nav */}
              <div
                className={`mobile ${
                  this.state.navOpen ? "nav__open" : "nav__closed"
                }`}
              >
                <button
                  className="dot"
                  aria-label="click to expand nav"
                  onClick={() => this.toggleNav()}
                >
                  <div className="dot__one"></div>
                  <div className="dot__two"></div>
                  <div className="dot__three"></div>
                </button>
                <nav className={`nav`}>
                  {this.state.navlinks.map((link, index) => (
                    <div
                      className="nav__link"
                      key={index}
                      onClick={() => this.toggleNav()}
                    >
                      <A to={link.link}>{link.title}</A>
                    </div>
                  ))}
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Header
