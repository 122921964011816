import React, { Component } from "react"
import TeamMember from "../../teamMember/teamMember"

import "./team.sass"
class Team extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <div id="team">
        <div
          className="section__container"
          style={
            {
              // backgroundColor: "white"
            }
          } //paddingTop: this.props.navHeight }}//cole added
        >
          <div className="section__container__content fit center space">
            <h2 className="section__title">{this.props.data.title}</h2>
            <div className="team">
              {this.props.data.acf.team_members.map((member, index) => (
                <TeamMember member={member} key={index} />
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Team
