import React, { Component } from "react"
import PortfolioIndexItem from "../portfolioItem/portfolioIndexItem"
import { CSSTransition } from "react-transition-group"

import "./portfolioIndex.sass"

class PortfolioIndex extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {
    console.log("from index comp: ", this.props)
  }
  render() {
    return (
      <div className="portfolio__index">
        {this.props.properties.map((p, index) => (
          <PortfolioIndexItem
            index={index}
            title={p.node.title}
            image={p.node.acf.hero_image.localFile.childImageSharp.fluid}
            alt={p.node.acf.hero_image.alt_text}
            //gets portfolio item index to pass proper property to expanded view, passed to parent portfolio.js to properly set expanded view property
            getClickedProperty={this.props.getClickedProperty}
          />
        ))}
      </div>
    )
  }
}

export default PortfolioIndex
