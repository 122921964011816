import React, { Component } from "react"
import { Link } from "gatsby"
class A extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isHome: false,
      homePath: "",
    }
  }
  scrollIntoView = id => {
    document.querySelector(id).scrollIntoView({
      behavior: "smooth",
    })
    if (this.props.onClick !== undefined) {
      this.props.onClick()
    }
  }

  componentDidMount() {
    // console.log(window.location);

    if (window.location.pathname === "/") {
      this.setState({ isHome: true })
    }
    this.setState({ homePath: window.location.origin })
  }
  render() {
    switch (this.props.to.charAt(0)) {
      case "#":
        return (
          <a
            onClick={() => this.scrollIntoView(this.props.to)}
            className={this.props.className}
          >
            {this.props.children}
          </a>
        )
      case "h":
        return (
          <a
            onClick={() =>
              this.props.onClick !== undefined ? this.props.onClick() : ""
            }
            href={this.props.href}
            className={this.props.className}
          >
            {this.props.children}
          </a>
        )
      default:
        return (
          <Link
            to={this.props.to}
            className={this.props.className}
            onClick={() =>
              this.props.onClick !== undefined ? this.props.onClick() : ""
            }
          >
            {this.props.children}
          </Link>
        )
    }
  }
}

export default A
