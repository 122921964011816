import React, { Component } from "react"
import SimpleMap from "../googleMap/googleMap"
import Img from "gatsby-image"
import "./portfolioItem.sass"
import SetP from "../utility/SetP"
class PortfolioItem extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {
    console.log("item", this.props.data)
  }
  render() {
    const { data } = this.props

    return (
      <div className="portfolio-item">
        <div className="portfolio-item__top">
          <div className="portfolio-item__top__info">
            <h2>{data.title}</h2>
            <SetP>{data.acf.description}</SetP>
          </div>
          {/* <div className="portfolio-item__top__map">
            <SimpleMap
              zoom={11}
              addresses={data.acf.properties.map(property => property.address)}
            />
          </div> */}
        </div>

        <div className="portfolio-item__property">
          {data.acf.properties.map((property, index) => (
            <div key={index} className="portfolio-item__property__single">
              <div className="portfolio-item__property__single__image">
                <Img
                  fluid={property.photo.localFile.childImageSharp.fluid}
                  alt={property.photo.alt}
                />
              </div>
              <div className="portfolio-item__property__single__info">
                <p>{property.name}</p>
                <p className="portfolio-item__property__single__info__sq">
                  {property.square_footage} sq ft
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default PortfolioItem
