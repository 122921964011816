import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Footer from "./footer"
import Header from "./header.js"
import ScrollContainer from "../autoScrollNav/scrollContainer"
import ScrollHeader from "../autoScrollNav/scrollHeader"
import ScrollPageSection from "../autoScrollNav/scrollPageSection"
import "../../styles/base.sass"

const Layout = ({ children, siteSettings }) => (
  <>
    <Header
      logo={siteSettings.full_logo_dark}
      // siteTitle={"Purple + Bold Starter"}
    />
    {children}

    {/* <Footer logo={siteSettings.full_logo_dark} /> */}
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
