import React, { Component } from "react"
import arrowIcon from "../../images/angle-arrow.svg"
class SpinningArrow extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <img
        style={{
          transform: this.props.toggle
            ? `rotateX(180deg) scale(${this.props.size})`
            : `scale(${this.props.size})`,
          transition: "all 0.5s ease",
        }}
        src={arrowIcon}
        alt="arrow pointing down to open or up to close dropdown section"
      />
    )
  }
}

export default SpinningArrow
