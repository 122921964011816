//https://www.npmjs.com/package/google-map-react
//https://www.npmjs.com/package/react-geocode

import React, { Component } from "react"
import GoogleMapReact from "google-map-react"
import Geocode from "react-geocode"
import mapStyles from "./mapStyles"
import ButtonRound from "../buttonRound/buttonRound"
// pin
const Pin = ({ svg }) => <img src={svg} />
// const Pin = ({ svg }) => <div style=></div>
// AIzaSyCl_dsFh-W92B-JNqfjKfo0ZHUSJ7roDNo
Geocode.setApiKey("AIzaSyCl_dsFh-W92B-JNqfjKfo0ZHUSJ7roDNo")

class SimpleMap extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mapAp: 1,
      cords: [
        {
          lat: 36.16,
          lng: -115.13,
        },
      ],
    }
    this.mapWrapper = React.createRef()
  }
  static defaultProps = {
    center: {
      lat: 36.16,
      lng: -115.13,
    },
    zoom: 12,
    createMapOptions: function(maps) {
      return {
        panControl: true,
        mapTypeControl: true,
        scrollwheel: true,
        styles: this.customStyles.mapStyles,
        minZoom: 2,
      }
    },
  }

  getLatLng = async address => {
    try {
      const response = await Geocode.fromAddress(address)
      const { lat, lng } = response.results[0].geometry.location
      return { lat, lng }
    } catch (error) {
      console.error(error, "google error")
    }
  }

  mapAddressToCord = async addresses => {
    return Promise.all(addresses.map(address => this.getLatLng(address)))
  }

  setWidth = () => {
    this.setState({ wrapperWidth: this.mapWrapper.current.offsetWidth })
  }

  phoneMapAspectRatio = m => {
    if (m.matches) {
      this.setState({ mapAp: 1 })
    } else {
      this.setState({ mapAp: 1.5 })
    }
  }

  componentDidMount() {
    const media = window.matchMedia("(max-width: 900px)")
    media.addListener(this.jsMedia)
    this.phoneMapAspectRatio(media)
    this.mapAddressToCord(this.props.addresses).then(res => {
      this.setState({ cords: res })
    })
    this.setWidth()
    console.log(this.props.isPhone)
  }

  render() {
    return (
      // Important! Always set the container height explicitly
      <div ref={this.mapWrapper}>
        <div
          style={{
            height: `${this.state.wrapperWidth / this.state.mapAp}px`,
            width: "100%",
            padding: "10px 0",
          }}
        >
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyCl_dsFh-W92B-JNqfjKfo0ZHUSJ7roDNo",
            }}
            defaultCenter={this.state.cords[0]}
            defaultZoom={this.props.zoom}
            options={this.props.createMapOptions}
            customStyles={mapStyles}
            customCords={this.state.cords}
          >
            {this.state.cords.map((cord, index) => (
              <Pin
                key={index}
                lat={cord.lat}
                lng={cord.lng}
                svg={
                  "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTIgMGMtNC4xOTggMC04IDMuNDAzLTggNy42MDIgMCA2LjI0MyA2LjM3NyA2LjkwMyA4IDE2LjM5OCAxLjYyMy05LjQ5NSA4LTEwLjE1NSA4LTE2LjM5OCAwLTQuMTk5LTMuODAxLTcuNjAyLTgtNy42MDJ6bTAgMTFjLTEuNjU3IDAtMy0xLjM0My0zLTNzMS4zNDItMyAzLTMgMyAxLjM0MyAzIDMtMS4zNDMgMy0zIDN6Ii8+PC9zdmc+"
                }
              />
            ))}
          </GoogleMapReact>
        </div>
        {/* <div
          style={{
            marginTop: '15px',
          }}
        >
          <ButtonRound
            innerText="Get Directions"
            pos="start"
            action={`https://www.google.com/maps/dir//${this.props.address
              .replace('<p>', '')
              .replace('</p>', '')
              .split(' ')
              .join('+')}/data=`}
            type="link"
          />
        </div> */}
      </div>
    )
  }
}

export default SimpleMap
