import React, { Component } from "react"
import Img from "gatsby-image"

import "./portfolioIndexItem.sass"

class PortfolioIndexItem extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  // componentWillMount() {
  //   console.log("from index item", this.props)
  // }
  render() {
    return (
      <div
        //passes index item of clicked propety to pass proper data to expanded property view
        onClick={() => this.props.getClickedProperty(this.props.index)}
        className={`portfolio__index__item ${
          this.props.index % 2 === 0 ? "even" : "odd"
        }`}
      >
        <span>
          <h3>{this.props.title}</h3>
          <div className="selected-line"></div>
        </span>

        <div className="portfolio__index__item__photo">
          <Img fluid={this.props.image} alt={this.props.alt} />
        </div>
      </div>
    )
  }
}

export default PortfolioIndexItem
