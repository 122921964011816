import React, { Component, Children } from "react"
import { throttle } from "lodash"

import ReactDOM from "react-dom"
import "./css/index.css"
let timeout
class ScrollContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      windowSize: { width: 0, height: 0 },
      navHeight: 25,
      onLanding: true,
    }
    this.navBarRef = React.createRef()
    this.containerRef = React.createRef()
  }

  componentDidMount() {
    this.updateDimensions()
    window.addEventListener("resize", this.updateWindowDimensions)
    window.addEventListener("scroll", throttle(this.checkForLanding, 700), true)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions)
    window.removeEventListener("scroll", this.checkForLanding)
  }

  landingToggle = value => this.setState({ onLanding: value })

  checkForLanding = () => {
    if (
      this.containerRef.current.scrollTop <
      this.state.windowSize.height - 200
    ) {
      this.landingToggle(true)
    } else {
      this.landingToggle(false)
    }
  }

  updateDimensions = () => {
    const windowSize = { width: window.innerWidth, height: window.innerHeight }
    let headerHeight
    const reactDomNones = ReactDOM.findDOMNode(this.refs.ScrollHeader)

    if (reactDomNones) {
      headerHeight = reactDomNones.childNodes[0].firstChild.clientHeight
    } else {
      headerHeight = 87
    }

    this.setState({
      navHeight: headerHeight,
      windowSize,
    })
  }

  render() {
    const nameProps = React.Children.map(this.props.children, child => {
      if (!React.isValidElement(child)) return
      return child.props.name
    })
    const childrenWithProps = React.Children.map(this.props.children, child => {
      if (!React.isValidElement(child)) return
      return React.cloneElement(child, {
        headerHeight: this.state.navHeight,
        windowSize: this.state.windowSize,
        onLanding: this.state.onLanding,
        allNames: nameProps,
        ref: child.type.name,
      })
    })
    return (
      <div
        ref={this.containerRef}
        className={`scrollContainer ${this.props.className} `}
        style={{ ...this.props.style }}
      >
        {childrenWithProps}
      </div>
    )
  }
}

export default ScrollContainer
