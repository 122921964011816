import React, { Component } from "react"
// import TextTransition, { presets } from "react-text-transition"
import AnimateHeight from "react-animate-height"
import SetP from "../utility/SetP"
import "./animatePair.sass"
class AnimatePair extends Component {
  constructor(props) {
    super(props)
    this.state = { selectedTitle: 0, height: "auto", topOffset: 0 }
    this.selectedTextRef = React.createRef()
    this.messageRef = React.createRef()
  }
  setSelected = i => {
    this.setState({ height: 1 }, () => {
      const maxLength = this.props.pairs.length - 1
      const newIndex = i > maxLength ? 0 : i < 0 ? maxLength : i
      this.setState({ selectedTitle: newIndex }, () => {
        this.setState({ topOffset: this.selectedTextRef.current.offsetTop })
        setTimeout(() => {
          this.setState({ height: "auto" })
          // console.log(
          //   this.selectedTextRef.current.offsetTop,
          //   this.selectedTextRef.currentR
          // )
        }, 325)
      })
    })
  }
  getMargin = () => {
    const total = this.props.pairs.length
    if (this.state.selectedTitle > total / 2) {
      return (
        this.state.topOffset -
        this.messageRef.current.getBoundingClientRect().height
      )
    } else {
      return this.state.topOffset
    }
  }
  render() {
    return (
      <div className="pair">
        <div className="pair__left">
          {this.props.pairs.map((pair, index) => (
            <p
              ref={
                this.state.selectedTitle === index ? this.selectedTextRef : null
              }
              key={index}
              onClick={() => this.setSelected(index)}
              className={`${
                this.state.selectedTitle === index
                  ? "pair__left__selected"
                  : "pair__left__others"
              }`}
            >
              {/* <TextTransition
                spring={presets.gentle}
                style={{ margin: "0 8px 0 0" }}
                inline
                text={this.state.selectedTitle === index ? ">" : ""}
                springConfig={presets.gentle}
              /> */}
              {this.state.selectedTitle === index ? ">" : ""}
              {pair.title}
              <progress className="pair__left__line" />
            </p>
          ))}
        </div>
        <div className="pair__right">
          <div
            className="pair__right__message"
            ref={this.messageRef}
            style={{ marginTop: this.getMargin() }}
          >
            {/* <div className="pair__right__message__body"> */}
            <AnimateHeight
              duration={300}
              height={this.state.height} // see props documentation below
            >
              <SetP>{this.props.pairs[this.state.selectedTitle].expanded}</SetP>
            </AnimateHeight>
            {/* </div> */}
            <div className="pair__right__message__controls">
              <button
                aria-label={`read more about the previous principle `}
                onClick={() => this.setSelected(this.state.selectedTitle - 1)}
              >
                <svg
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  fillRule="evenodd"
                  clipRule="evenodd"
                >
                  <path d="M11 2.206l-6.235 7.528-.765-.645 7.521-9 7.479 9-.764.646-6.236-7.53v21.884h-1v-21.883z" />
                </svg>
              </button>
              <button
                aria-label={`read more about read more about the next principle`}
                onClick={() => this.setSelected(this.state.selectedTitle + 1)}
              >
                <svg
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  fillRule="evenodd"
                  clipRule="evenodd"
                >
                  <path d="M11 21.883l-6.235-7.527-.765.644 7.521 9 7.479-9-.764-.645-6.236 7.529v-21.884h-1v21.883z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AnimatePair
