import React, { Component } from "react"
import Img from "gatsby-image"

import "./aboutIcon.sass"

class AboutIcon extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <div className="about__icon__container">
        <div className="about__icon__container__icon">
          <div className="gatsby-image-container">
            <Img fluid={this.props.icon} />
          </div>
        </div>
        <div className="about__icon__container__text">
          <h3>{this.props.text}</h3>
        </div>
      </div>
    )
  }
}

export default AboutIcon
