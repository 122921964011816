import React, { Component } from "react"
import VisibilitySensor from "react-visibility-sensor"
import "./css/index.css"
class ScrollPageSection extends Component {
  constructor(props) {
    super(props)
    this.state = { isPhone: false }
  }
  componentDidMount() {
    const media = window.matchMedia("(max-width: 900px)")
    this.jsMedia(media)
    media.addListener(this.jsMedia)
  }
  jsMedia = m => {
    this.setState({ isPhone: m.matches })
  }
  inView = isInView => {
    if (isInView) {
      window.history.pushState(
        {},
        "",
        `${this.props.name === "home" ? "/" : `#${this.props.name}`}`
      )
    }
    if (this.props.onInView !== undefined) {
      this.props.onInView(this.props)
    }
  }
  // setHeight = () =>
  //   this.props.isLanding
  //     ? ""
  //     : !this.state.isPhone
  //     ? this.props.windowSize.height - this.props.headerHeight
  //     : "auto"
  setHeight = () =>
    this.props.isLanding ? "" : !this.state.isPhone ? "auto" : "auto"
  renderSpacer = props =>
    props.isLanding || this.state.isPhone ? (
      ""
    ) : (
      <div
        style={{
          height: props.headerHeight,
          // marginBottom: "10px",
          // background: "teal",
        }}
      ></div>
    )

  render() {
    const childrenWithProps = React.Children.map(this.props.children, child => {
      if (!React.isValidElement(child)) return
      return React.cloneElement(child, {
        allNames: this.props.allNames,
        navHeight: this.props.headerHeight,
      })
    })
    return (
      <VisibilitySensor
        onChange={this.inView}
        partialVisibility={this.state.isPhone}
      >
        <div
          style={{
            ...this.props.style,
          }}
          className={`section ${
            this.props.className !== undefined ? this.props.className : ""
          } `}
          id={this.props.name}
        >
          {/* {this.renderSpacer(this.props)} */}
          <div
            style={{
              height: this.setHeight(),
              // paddingTop: this.props.headerHeight,
            }}
          >
            {childrenWithProps}
          </div>
        </div>
      </VisibilitySensor>
    )
  }
}
export default ScrollPageSection
