import React, { Component } from "react"
import SimpleMap from "../googleMap/googleMap"
import ExpandedPropertyGridItem from "../expandedPropertyGridItem/expandedPropertyGridItem"
import "./portfolioExpanded.sass"
import SetP from "../utility/SetP"
import Img from "gatsby-image"

class PortfolioExpanded extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {
    console.log("EXPANDED: ", this.props.property.node.acf.description)
    console.log("from item: ", this.props)
  }
  render() {
    return (
      <div className="portfolio__expanded">
        <div className="portfolio__expanded__top">
          <h3>{this.props.property.node.title}</h3>
          <div className="portfolio__expanded__top__info">
            {/* <div className="portfolio__expanded__top__info__text">
              <SetP>{this.props.property.node.acf.description}</SetP>
            </div> */}

            <div className="portfolio__expanded__top__info__map">
              {this.props.property.node.acf.show_image_inside ? (
                <Img
                  fluid={
                    this.props.property.node.acf.hero_image.localFile
                      .childImageSharp.fluid
                  }
                  alt={this.props.property.node.acf.hero_image.alt_text}
                />
              ) : (
                ""
              )}
              {/* <SimpleMap
                isPhone={this.props.isPhone}
                zoom={10}
                addresses={this.props.property.node.acf.properties.map(
                  property => property.address
                )}
              /> */}
            </div>
          </div>
        </div>
        <div className="portfolio__expanded__bottom">
          <div className="portfolio__expanded__bottom__title">
            <h5>Additional Properties</h5>
          </div>
          <div className="portfolio__expanded__bottom__grid">
            {this.props.property.node.acf.properties.map((p, index) => (
              <ExpandedPropertyGridItem
                fluid={p.photo.localFile.childImageSharp.fluid}
                alt={p.photo.alt_text}
                title={p.name}
                squareft={Number(p.square_footage).toLocaleString() + " sq ft"}
                key={index}
              />
            ))}
          </div>
        </div>
      </div>
    )
  }
}

export default PortfolioExpanded
