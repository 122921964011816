import React, { Component } from "react"
import SetP from "../../utility/SetP"
import AnimatePair from "../../animatePair/animatePair"
import "./ip.sass"
import DropDown from "../../utility/dropDown/dropDown"
class Ip extends Component {
  constructor(props) {
    super(props)
    this.state = { isPhone: false, openDropDowns: [] }
  }

  componentDidMount() {
    const media = window.matchMedia("(max-width: 730px)")
    this.jsMedia(media)
    media.addListener(this.jsMedia)
  }
  jsMedia = m => {
    this.setState({ isPhone: m.matches })
  }
  dropDownToggle = i => {
    const openDropDowns = this.state.openDropDowns.map(d =>
      d.index === i ? { ...d, open: !d.open } : { ...d, open: false }
    )

    this.setState({ openDropDowns })
  }
  renderPairs = () => {
    if (true) {
      if (this.state.openDropDowns.length === 0) {
        const openDropDowns = this.props.data.acf.investment_principles.map(
          (d, index) => {
            return { index, open: false }
          }
        )
        this.setState({ openDropDowns })
      }
      return (
        <div>
          {this.props.data.acf.investment_principles.map((pair, index) => (
            <DropDown
              isOpen={this.state.openDropDowns.find(d => d.index === index)}
              title={pair.title}
              tag="h4"
              key={index}
              index={index}
              isPhone={this.state.isPhone}
              toggle={this.dropDownToggle}
            >
              <div className="ip__expanded">{pair.expanded}</div>
            </DropDown>
          ))}
        </div>
      )
    } else {
      return <AnimatePair pairs={this.props.data.acf.investment_principles} />
    }
  }

  render() {
    return (
      <div
        className="section__container"
        id="ip"
        style={{
          backgroundColor: "rgb(239, 239, 239)",
          // paddingTop: this.props.navHeight,//cole added
        }}
      >
        <div className="section__container__content fit center space">
          <h2 className="section__title investment__title">
            {this.props.data.title}
          </h2>
          <div className="investment">
            <SetP className="investment__message">
              {this.props.data.acf.body}
            </SetP>
            <h3>{this.props.data.acf.section_title}</h3>
            <div className="investment__principles">{this.renderPairs()}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default Ip
