import React, { Component } from "react"
import "./css/index.css"
class ScrollHeader extends Component {
  constructor(props) {
    super(props)
    this.state = { scrollY: 0 }
  }
  componentDidCatch() {
    this.setState({ scrollY: window.scrollY })
  }
  render() {
    const childrenWithProps = React.Children.map(this.props.children, child => {
      if (!React.isValidElement(child)) return
      return React.cloneElement(child, {
        allNames: this.props.allNames,
        onLanding: this.props.onLanding,
      })
    })

    return (
      <div
        style={{
          ...this.props.style,
        }}
        className={`scrollHeader ${this.props.className} `}
      >
        <div
          style={{
            height: this.state.scrollY > 0 ? this.props.headerHeight : 0,
            // position: "absolute" : "relative",
          }}
          className="scrollHeaderSpacer"
        >
          {childrenWithProps}
        </div>
      </div>
    )
  }
}

export default ScrollHeader
