import React, { Component } from "react"
import { Link } from "gatsby"
import "./footer.sass"
import Img from "gatsby-image"
import A from "../utility/link/A"
import BuiltBy from "./builtby"
import { throttle } from "lodash"

class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      navlinks: [
        {
          title: "Home",
          link: "/",
        },
        {
          title: "Strategy",
          link: "strategy",
        },
        {
          title: "Team",
          link: "team",
        },
        {
          title: "Portfolio",
          link: "portfolio",
        },
        {
          title: "Contact",
          link: "contact",
        },
      ],
    }
  }
  componentDidMount() {
    this.setState({ onHome: window.location.pathname === "/" })
    console.log(this.props)
  }

  // newNavLinks = this.props.allNames.map(name => {
  //   return {
  //     title: name.charAt(0).toUpperCase() + name.slice(1),
  //     link: `#${name}`,
  //   }
  // })
  render() {
    return (
      // <div
      //   className="alt__section__container"
      //   style={{
      //     display: this.state.onHome ? "none" : "",
      //   }}
      // >
      //   <div className="alt__section__container__content">
      //     <footer className="footer">
      //       <div className="footer__logo">
      //         <div>
      //           <A to={"#home"}>
      //             <Img
      //               fluid={this.props.logo.localFile.childImageSharp.fluid}
      //               alt={this.props.logo.alt_text}
      //             />
      //           </A>
      //         </div>
      //       </div>

      //       <div className="desktop">
      //         <nav className="nav">
      //           {this.state.navlinks.map((link, index) => (
      //             <div className="nav__link" key={index}>
      //               <A to={link.link}>{link.title}</A>
      //             </div>
      //           ))}
      //         </nav>
      //       </div>
      //     </footer>
      //   </div>
      <div className="copy">
        <p>
          © {new Date().getFullYear()} Prospect Street Partners. All Rights
          Reserved.
        </p>
      </div>
      // </div>
    )
  }
}

export default Footer
