import React, { Component } from "react"
import Img from "gatsby-image"
import { SlideDown } from "react-slidedown"
import SetP from "../utility/SetP"

import "react-slidedown/lib/slidedown.css"

import "./teamMember.sass"

import arrowIcon from "../../images/angle-arrow.svg"

class TeamMember extends Component {
  constructor(props) {
    super(props)
    this.state = {
      closed: true,
      shortenedText: null,
      remainingText: null,
    }
  }
  componentWillMount() {
    this.splitText(this.props.member.bio)
  }
  splitText = text => {
    const limit = 250
    const length = text.length
    const shortenedText = text.substring(0, limit) + " . . ."
    const remainingText = text.substring(limit, length)
    this.setState({
      shortenedText,
      remainingText,
    })
  }
  toggleSlide = () => {
    this.setState({
      closed: !this.state.closed,
    })
  }
  // styleButton = dropdownClosed => {
  //   console.log("styling button")
  //   switch (dropdownClosed) {
  //     case dropdownClosed:
  //       break
  //     case !dropdownClosed:
  //       const buttonStyle = { transform: "rotate(180deg)" }
  //       return buttonStyle
  //   }
  // }
  render() {
    return (
      <div className="team-member">
        <div className="team-member__photo">
          <div className="team-member__photo__container">
            <div className="team-member__photo__container__img">
              <Img
                fluid={this.props.member.image.localFile.childImageSharp.fluid}
                alt={this.props.member.image.alt}
              />
            </div>
          </div>
        </div>
        <div className="team-member__name">
          <h3>{this.props.member.name}</h3>
          <h4 className="team-member__name__title">
            {this.props.member.title}
          </h4>
          {/* <div className="team-member__bio">
            <SetP>{this.state.shortenedText}</SetP>
            <SlideDown
              className="team-member__bio__dropdown"
              closed={this.state.closed}
            >
              <SetP>{this.state.remainingText}</SetP>
            </SlideDown>
            <button onClick={this.toggleSlide}>
              See Full Bio{" "}
              <img
                src={arrowIcon}
                alt="arrow pointing down to open or up to close dropdown section"
                style={{
                  transform: this.state.closed
                    ? "rotate(0deg)"
                    : "rotate(180deg)",
                }}
              />
            </button>
          </div> */}
        </div>
      </div>
    )
  }
}

export default TeamMember
