import React, { Component } from "react"
import { SlideDown } from "react-slidedown"
import "react-slidedown/lib/slidedown.css"
import SpinningArrow from "../spinningArrow"
import "./dropDown.sass"

import arrowIcon from "../../../images/angle-arrow.svg"

class DropDown extends Component {
  constructor(props) {
    super(props)
    this.state = { toggle: false }
  }
  renderContent = () => {
    if (this.props.isOpen) {
      if (this.props.isOpen.open) {
        return this.props.children
      } else {
        return ""
      }
    }
  }
  render() {
    const TitleTag = this.props.tag ? this.props.tag : "p"
    return (
      <div className="drop">
        <button
          onClick={() => this.props.toggle(this.props.index)}
          className="drop__title"
        >
          <TitleTag className="dropdown__title">
            {this.props.title}{" "}
            <div className="dropdown__icon">
              {/* <img
                src={arrowIcon}
                alt="arrow pointing down to open or up to close dropdown section"
              /> */}
              <SpinningArrow
                size={this.props.isPhone ? 1 : 2}
                toggle={this.state.toggle}
              />
            </div>
          </TitleTag>
        </button>
        <SlideDown className={"drop__body"}>{this.renderContent()}</SlideDown>
      </div>
    )
  }
}

export default DropDown
